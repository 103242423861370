import React from 'react';
import CookiePolicy from '../containers/Legal/CookiePolicy';
import Layout from '../containers/Layout';

const CookiePolicyPage: React.FC = () => (
  <Layout title="Solvana | Cookie Policy">
    <CookiePolicy />
  </Layout>
);

export default CookiePolicyPage;
