import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingBottom: theme.spacing(8),
      '& a': {
        color: theme.palette.primary.main,
        fontSize: '16px',
      },
      '& p': {
        marginTop: 0,
        fontSize: '16px',
      },
    },
    title: {
      fontSize: '48px',
      marginBottom: theme.spacing(5),
    },
    companyName: {
      fontWeight: 'bold',
    },
  }),
);
